import { createConstants } from "../../utils";
import organizationsConfig from "../../../../../src/organizations.conf.json";
// basic
const USER_LOGIN = "USER_LOGIN";
const USER_TWOFA_GENERATE_QR_CODE = "USER_TWOFA_GENERATE_QR_CODE";
const USER_TWOFA_VERIFY_CODE = "USER_TWOFA_VERIFY_CODE";
const USER_TWOFA_DISABLE_CODE = "USER_TWOFA_DISABLE_CODE";
const USER_RESET_PASSWORD = "USER_RESET_PASSWORD";
const USER_RESET_FORGOTTEN_PASSWORD = "USER_RESET_FORGOTTEN_PASSWORD";
const USER_RESET_FORGOTTEN_PASSWORD_EMAIL =
  "USER_RESET_FORGOTTEN_PASSWORD_EMAIL";
const USER_SIGNUP_LAST_STEP = "USER_SIGNUP_FIRST_STEP";
const GET_USER_DATA = "GET_USER_DATA";
const GET_USER_DOCUMENTS = "GET_USER_DOCUMENTS";
const UPLOAD_DOCUMENTS = "UPLOAD_DOCUMENTS";
const UPLOAD_IMAGE = "UPLOAD_IMAGE";
const SAVE_USER_NOTIFICATION_OPTIONS = "SAVE_USER_NOTIFICATION_OPTIONS";
const GET_USER_CONTACT_OPTIONS = "GET_USER_CONTACT_OPTIONS";
const SEND_VERIFICATION_CODE_UNAUTH = "SEND_VERIFICATION_CODE_UNAUTH";
const RESEND_VERIFICATION_CODE = "RESEND_VERIFICATION_CODE";
const RESEND_VERIFICATION_CODE_BY_EMAIL = "RESEND_VERIFICATION_CODE_BY_EMAIL";
const VERIFY_USER_PHONE = "VERIFY_USER_PHONE";
const VERIFY_USER_EMAIL = "VERIFY_USER_EMAIL";
const VERIFY_REFERRAL_CODE = "VERIFY_REFERRAL_CODE";
const GET_ADDRESS_BY_ZIPCODE = "GET_ADDRESS_BY_ZIPCODE";

export const USER_SIGNUP_FIRST_STEP = "USER_SIGNUP_FIRST_STEP";
export const USER_CLEAN_UP_LOGIN_ERROR = "USER_CLEAN_UP_LOGIN_ERROR";
export const SET_USER_DOCS = "SET_USER_DOCS";
export const SET_SIGNUP_DOCS = "SET_SIGNUP_DOCS";

export const SET_CARD_FORM = "SET_CARD_FORM";

export const CREATE_CREDIT_CARD = "CREATE_CREDIT_CARD";
export const REMOVE_CREDIT_CARD = "REMOVE_CREDIT_CARD";
export const CREATE_USER_CARD = "CREATE_USER_CARD";

export const EDIT_USER = "EDIT_USER";
export const GET_USER_DOCUMENT_CHECK_ID_REPORT =
  "GET_USER_DOCUMENT_CHECK_ID_REPORT";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_CHANGE_PASSWORD = "USER_CHANGE_PASSWORD";
export const CLEAR_USER_DATA = "CLEAR_USER_DATA";
export const RESET_REFERRAL_CODE_DATA = "RESET_REFERRAL_CODE_DATA";
export const GET_USER_TRANSACTIONS = "GET_USER_TRANSACTIONS";
export const GET_USER_CARDS = "GET_USER_CARDS";

const SEND_EMAIL_TO_ORGANIZATION = "SEND_EMAIL_TO_ORGANIZATION";

export const createCreditCardTypes = createConstants(CREATE_CREDIT_CARD);
export const removeCreditCardTypes = createConstants(REMOVE_CREDIT_CARD);

export const userLoginTypes = createConstants(USER_LOGIN);
export const userTwoFAGenerateQRTypes = createConstants(
  USER_TWOFA_GENERATE_QR_CODE,
);
export const userTwoFAVerifyTypes = createConstants(USER_TWOFA_VERIFY_CODE);
export const userTwoFADisableTypes = createConstants(USER_TWOFA_DISABLE_CODE);

export const uploadDocumentsTypes = createConstants(UPLOAD_DOCUMENTS);
export const uploadImageTypes = createConstants(UPLOAD_IMAGE);
export const getUserDocumentsTypes = createConstants(GET_USER_DOCUMENTS);
export const userResetPasswordTypes = createConstants(USER_RESET_PASSWORD);
export const userResetForgottenPasswordTypes = createConstants(
  USER_RESET_FORGOTTEN_PASSWORD,
);
export const userResetForgottenPasswordEmailTypes = createConstants(
  USER_RESET_FORGOTTEN_PASSWORD_EMAIL,
);
export const userSignupLastStepTypes = createConstants(USER_SIGNUP_LAST_STEP);
export const getUserDataTypes = createConstants(GET_USER_DATA);
export const editUserTypes = createConstants(EDIT_USER);
export const changePasswordTypes = createConstants(USER_CHANGE_PASSWORD);
export const getUserDocumentCheckIdReportTypes = createConstants(
  GET_USER_DOCUMENT_CHECK_ID_REPORT,
);

export const saveUserNotificationOptionsTypes = createConstants(
  SAVE_USER_NOTIFICATION_OPTIONS,
);
export const getUserContactOptionsTypes = createConstants(
  GET_USER_CONTACT_OPTIONS,
);
export const resendVerificationCodeTypes = createConstants(
  RESEND_VERIFICATION_CODE,
);
export const resendVerificationCodeByEmailTypes = createConstants(
  RESEND_VERIFICATION_CODE_BY_EMAIL,
);
export const sendVerificationCodeUnAuthTypes = createConstants(
  SEND_VERIFICATION_CODE_UNAUTH,
);
export const getAddressByZipCodeTypes = createConstants(GET_ADDRESS_BY_ZIPCODE);

export const verifyUserPhoneTypes = createConstants(VERIFY_USER_PHONE);
export const verifyUserEmailTypes = createConstants(VERIFY_USER_EMAIL);
export const getUserTransactionsTypes = createConstants(GET_USER_TRANSACTIONS);
export const getUserCardsTypes = createConstants(GET_USER_CARDS);

export const sendEmailToOrganizationTypes = createConstants(
  SEND_EMAIL_TO_ORGANIZATION,
);
export const verifyReferralCodeTypes = createConstants(VERIFY_REFERRAL_CODE);

// endpoints
export const LOGIN_ENDPOINT = "/unAuth/login";
export const TWOFA_GENERATE_QR_ENDPOINT = "/user/2fa/generate/mobile";
export const TWOFA_VERIFY_ENDPOINT = "/user/2fa/verify/mobile ";
export const TWOFA_DISABLE_ENDPOINT = "/user/2fa/disable/mobile";
export const UPLOAD_IMAGE_ENDPOINT = "/user/image/upload";
export const GET_USER_DOCUMENTS_ENDPOINT = "/user/getDocuments";
export const SIGNUP_ENDPOINT = "/unAuth/signup";
export const RESET_PASSWORD_ENDPOINT = "/unAuth/reset";
export const GET_USER_DATA_ENDPOINT = "/user/accountData";
export const USER_CHANGE_PASSWORD_ENDPOINT = "user/changeUserPassword";
export const EDIT_USER_ENDPOINT = "/user/edit";
export const EDIT_USER_ONFIDO_ENDPOINT = "/user/edit";
export const GET_USER_DOCUMENT_CHECK_ID_REPORT_ENDPOINT =
  "/user/getUserDocumentCheckIdReport/mobile";
export const CREATE_USER_CARD_ENDPOINT = "/user/card/add";
export const REMOVE_USER_CARD_ENDPOINT = "/user/card/delete";
export const QUERY_ORG_ENDPOINT = "/user/queryOrg";
export const GET_USER_CONTACT_OPTIONS_ENDPOINT = "/user/getUserContactOptions";
export const SEND_VERIFICATION_CODE_UNAUTH_ENDPOINT = "/unAuth/sendCode";
export const RESEND_VERIFICATION_CODE_ENDPOINT = "/user/sendCode";
export const RESEND_VERIFICATION_CODE_BY_EMAIL_ENDPOINT =
  "/unAuth/sendCodeByEmail";
export const VERIFY_USER_PHONE_ENDPOINT = "/user/verifyUserPhoneNumber";
export const VERIFY_USER_EMAIL_ENDPOINT = "/user/verifyUserEmail";
export const VERIFY_REFERRAL_CODE_ENDPOINT = "/unAuth/verifyReferralCode";
export const GET_ADDRESS_BY_ZIPCODE_ENDPOINT = "/unAuth/getAddress";
export const GET_USER_CARDS_ENDPOINT = "/user/card/getCards";
export const GET_USER_TRANSACTIONS_ENDPOINT =
  "/transactionNew/senderRecentTransactions";
export const SEND_EMAIL_TO_ORGANIZATION_ENDPOINT = "/organization/emailSupport";

export enum UserStoreConstants {
  USER_DOCUMENTS = "userDocuments",
  USER_CONTACT_OPTIONS = "userContactOptionsList",
  USER_TRANSACTIONS = "userTransactions",
  AUTOCOMPLETE_ADDRESSES = "autocompleteAddresses",
  USER_CARDS = "userCards",
  SIGNUP_DOCUMENTS = "signupDocuments",
}

export enum docTypes {
  ADDITIONAL_ID = "ADDITIONAL_ID",
  ARTICLES_OF_ASSOCIATION = "ARTICLES_OF_ASSOCIATION",
  BILL_OF_LADEN = "BILL_OF_LADEN",
  CERTIFICATE_OF_INCORPORATION = "CERTIFICATE_OF_INCORPORATION",
  COMPANY_FINANCIAL_STATEMENT = "COMPANY_FINANCIAL_STATEMENT",
  COPY_OF_ID_FROM_LEGAL_REPRESENTATIVE = "COPY_OF_ID_FROM_LEGAL_REPRESENTATIVE",
  CUSTOMER_DECLARATION = "CUSTOMER_DECLARATION",
  DRIVING_LICENCE = "DRIVING_LICENCE",
  EUROPEAN_ID = "EUROPEAN_ID",
  ID = "ID",
  ID_BACK_SIDE = "ID_BACK_SIDE",
  ID_BACK_SIDE2 = "ID_BACK_SIDE2",
  ID2 = "ID2",
  INCOME_STATEMENT = "INCOME_STATEMENT",
  INDIVIDUALS_WITH_RIGHT_TO_ACT_ON_BEHALF_OF_COMPANY = "INDIVIDUALS_WITH_RIGHT_TO_ACT_ON_BEHALF_OF_COMPANY",
  INVOICE = "INVOICE",
  KAMER_VAN_KOOPHANDEL_UITTREKSEL = "KAMER_VAN_KOOPHANDEL_UITTREKSEL",
  KNOW_YOUR_CUSTOMER_QUESTIONNAIRE = "KNOW_YOUR_CUSTOMER_QUESTIONNAIRE",
  KYC = "KYC",
  LOAN_AGREEMENT = "LOAN_AGREEMENT",
  MEMORANDUM_OF_ASSOCIATION = "MEMORANDUM_OF_ASSOCIATION",
  NOTARY_DEED = "NOTARY_DEED",
  OTHER = "OTHER",
  PASSPORT = "PASSPORT",
  PASSPORT_PHOTO = "PASSPORT_PHOTO",
  POLITICALLY_EXPOSED_PERSON = "POLITICALLY_EXPOSED_PERSON",
  POWER_OF_ATTORNEY = "POWER_OF_ATTORNEY",
  PRO_FORMA = "PRO_FORMA ",
  PROOF_OF_ADDRESS = "PROOF_OF_ADDRESS",
  PROOF_OF_ADDRESS_OF_FIRM = "PROOF_OF_ADDRESS_OF_FIRM",
  PROOF_OF_NET_VALUE = "PROOF_OF_NET_VALUE",
  PROOF_OF_OCCUPATION = "PROOF_OF_OCCUPATION",
  PUBLIC_PERSONALITY = "PUBLIC_PERSONALITY",
  RESIDENCE_PERMIT = "RESIDENCE_PERMIT",
  SERVICE_APPLICANT_DECLARATION_FORM = "SERVICE_APPLICANT_DECLARATION_FORM",
  SHAREHOLDERS_20_PERCENT_ID_AND_PROOF_OF_ADDRESS = "SHAREHOLDERS_20_PERCENT_ID_AND_PROOF_OF_ADDRESS",
  SHAREHOLDERS_20_PERCENT_PROOF_OF_ADDRESS_AND_COUNTRY_OF_RESIDENCE = "SHAREHOLDERS_20_PERCENT_PROOF_OF_ADDRESS_AND_COUNTRY_OF_RESIDENCE",
  SOURCE_OF_FUNDS = "SOURCE_OF_FUNDS",
  TAX_REGISTRATION_DOCUMENT = "TAX_REGISTRATION_DOCUMENT",
}

export const SAVE_USER_NOTIFICATION_OPTIONS_ENDPOINT =
  "/user/saveUserContactOptions";
export const RESET_FORGOTTEN_PASSWORD_ENDPOINT = "/unAuth/forgotPassword";
export const RESET_FORGOTTEN_PASSWORD_EMAIL_ENDPOINT =
  "/unAuth/forgotPasswordEmail";

const { ID, ID_BACK_SIDE, PROOF_OF_ADDRESS, PROOF_OF_OCCUPATION } = docTypes;
const { unitylink } = organizationsConfig;
