import { createConstants } from "../../utils";

import { PaymentProcessors } from "./organization.types";

const GET_ORGANIZATION_DATA = "GET_ORGANIZATION_DATA";
const GET_MOBILE_CONFIG = "GET_MOBILE_CONFIG";
const GET_RECIPIENT_RELATIONSHIPS = "GET_RECIPIENT_RELATIONSHIPS";
const GET_PURPOSES_OF_TRANSFER = "GET_PURPOSES_OF_TRANSFER";
const GET_SOURCES_OF_FUNDS = "GET_SOURCE_OF_FUNDS";
const GET_DOC_TYPES = "GET_DOC_TYPES";
const GET_ID_DOC_TYPES = "GET_ID_DOC_TYPES";
const GET_LIST_OF_AGENTS = "GET_LIST_OF_AGENTS";
const GET_USER_ACTION_NEEDED = "GET_USER_ACTION_NEEDED";

export const SETUP_SECURE_TRADING = "SETUP_SECURE_TRADING";
export const getOrganizationDataTypes = createConstants(GET_ORGANIZATION_DATA);
export const getMobileConfigTypes = createConstants(GET_MOBILE_CONFIG);
export const getRecipientRelationshipsTypes = createConstants(
  GET_RECIPIENT_RELATIONSHIPS,
);
export const getPurposesOfTransferTypes = createConstants(
  GET_PURPOSES_OF_TRANSFER,
);
export const getSourcesOfFundsTypes = createConstants(GET_SOURCES_OF_FUNDS);

export const getDocTypes = createConstants(GET_DOC_TYPES);

export const getIdDocTypes = createConstants(GET_ID_DOC_TYPES);

export const getListOfAgentsTypes = createConstants(GET_LIST_OF_AGENTS);

export const getUserActionNeededTypes = createConstants(GET_USER_ACTION_NEEDED);

// Endpoints
export const GET_ORGANIZATION_DATA_ENDPOINT = "/unAuth/organization/data";
export const GET_USER_NEEDED_ENDPOINT = "/user/actions";
export const GET_RECIPIENT_RELATIONSHIPS_ENDPOINT =
  "/organization/getRecipientRelationship";
export const GET_PURPOSES_OF_TRANSFER_ENDPOINT =
  "/organization/getTransferPurpose";
export const GET_SOURCE_OF_FUNDS_ENDPOINT = "/organization/getSourceOfFunds";

export const GET_MOBILE_CONFIG_ENDPOINT =
  "/unAuth/mobile/getMobileConfiguration";

export const GET_LIST_OF_AGENTS_ENDPOINT = "/agent/findByOrganization";

export const GET_DOC_TYPES_ENDPOINT = "/user/docTypes";
export const GET_ID_DOC_TYPES_ENDPOINT = "/user/idTypes";

export enum OrganizationStoreConstants {
  ORGANIZATION_DATA = "organizationData",
  MOBILE_CONFIGURATION = "mobileConfiguration",
  EXTRA_OPTIONS = "extraOptions",
  ORGANIZATION_AGENTS = "organizationAgents",

  TRANSFER_DATA_BY_COUNTRY = "transferDataByCountry",
  SOURCE_CURRENCIES = "sourceCurrencies",
  DESTINATION_CURRENCIES = "destinationCurrencies",
  RECIPIENT_RELATIONSHIPS = "recipientRelationships",
  PURPOSES_OF_TRANSFER = "purposesOfTransfer",
  SOURCES_OF_FUNDS = "sourcesOfFunds",
  PAYMENT_PROCESSORS_BY_COUNTRY = "paymentProcessorByCountry",
  DOC_TYPES = "docTypes",
  ID_DOC_TYPES = "idDocTypes",
  REQUIRED_DOCS = "requiredDocuments",
  SUPPORT_INFO = "supportInfo",
}

export const VALID_RELATIONSHIPS = [
  "family",
  "friend",
  "self",
  "employer",
  "employee",
  "other",
];

export const VALID_PURPOSE_OF_TRANSFERS = [
  "family support",
  "friend support",
  "sending to self",
  "purchase of services",
  "property payment",
  "gift/charity/donation",
  "other",
];

export const paymentProcessorsSourcesConfig: {
  [prop: string]: {
    test: string;
    live: string;
  };
} = {
  [PaymentProcessors.SAGEPAY]: {
    test: "https://pi-test.sagepay.com/api/v1/js/sagepay.js",
    live: "https://pi-live.sagepay.com/api/v1/js/sagepay.js",
  },
};

const socialMediaLinks = [
  {
    name: "Facebook",
    url: "https://www.facebook.com/Unitylinkuk/",
    imageUrl:
      "https://s3.eu-west-2.amazonaws.com/public.valapay.com/files/assets/social/facebook.svg",
  },
  {
    name: "Twitter",
    url: "https://twitter.com/hashtag/UnityLink",
    imageUrl:
      "https://s3.eu-west-2.amazonaws.com/public.valapay.com/files/assets/social/twitter.svg",
  },
  {
    name: "Instagram",
    url: "https://www.instagram.com/unitylinkuk/",
    imageUrl:
      "https://s3.eu-west-2.amazonaws.com/public.valapay.com/files/assets/social/instagram.svg",
  },
];

export const organizationAddresses = {
  NL:
    "Unity Monetary Services BV, Bijlmerdreef 1129A-1131A, 1103 TT  AMSTERDAM",
  GB:
    "Unitylink Financial Service Limited, 4th Floor, 5-17 Hammersmith Grove, The Triangle, Hammersmith Grove, London, W6 0LG",
  FR:
    "Unitylink Financial Service Limited, 4th Floor, 5-17 Hammersmith Grove, The Triangle, Hammersmith Grove, London, W6 0LG",
};

export const organizationSupportInfo = {
  NL: {
    phoneNumber: "+31 20 695 7122",
    email: "info@unitymoney.nl",
    socialMediaLinks,
  },
  GB: {
    phoneNumber: "+44-208-772-2160",
    email: "online@unitylink.com",
    socialMediaLinks,
  },
  FR: {
    phoneNumber: "+44-208-772-2160",
    email: "online@unitylink.com",
    socialMediaLinks,
  },
};

export const organizationWebClients = {
  GB: "https://online.unitylink.com/",
  NL: "https://online.unitymoney.nl/",
  DE: "https://online.unitymoney.de/",
  IT: "https://online.unitymoney.it/",
};
